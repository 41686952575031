import { IFiltersSections } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/filters/interfaces/sections/filters-sections.interface';

export const TOWS_FILTERS_SECTIONS: IFiltersSections = {
  name: 'tow',
  shortCode: 'tow',
  exportRoute: 'tows/export',
  sections: [
    {
      options: [
        {
          filterMethod: 'keywords',
          filterPropertyKeys: ['search'],
          filterMethodConfig: {
            useForSearch: true,
            title: 'Search',
            label: 'By Tow Route/ID (Use X- for single letter)',
          },
        },
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'tow-status',
          },
          filterPropertyKeys: ['metadata.status'],
          definitionAlias: 'tow-status',
        },
        {
          filterMethod: 'years-drop-down',
          filterMethodConfig: {
            title: 'Year',
            min: 1900,
            max: 2026,
            reversed: true,
          },
          filterPropertyKeys: ['metadata.year'],
        },
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'ships',
          },
          filterPropertyKeys: ['metadata.shipId'],
          definitionAlias: 'ships',
          filterValueType: 'OBJECT_ID',
        },
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'cprs',
            simpleFilters: {
              inUse: true,
            },
            pageSize: 500,
          },
          filterPropertyKeys: ['metadata.cprId'],
          definitionAlias: 'cprs',
          filterValueType: 'OBJECT_ID',
        },
        {
          filterMethod: 'dropdown',
          filterMethodConfig: {
            title: 'Validity',
            options: [
              {
                name: 'Any',
                value: null,
              },
              {
                name: 'OK',
                value: 'OK',
              },
              {
                name: 'JAM',
                value: 'JAM',
              },

              {
                name: 'LOST',
                value: 'LOST',
              },
              {
                name: 'QUAL',
                value: 'QUAL',
              },
              {
                name: 'QUA',
                value: 'QUA',
              },
            ],
            defaultValue: {},
          },
          filterPropertyKeys: ['metadata.validity'],
        },
      ],
    },
  ],
};
