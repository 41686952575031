import { FilterConditionType } from '@suvo-bi-lib';
import { IFiltersSections } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/filters/interfaces/sections/filters-sections.interface';

export const SAMPLES_FILTERS_SECTIONS: IFiltersSections = {
  name: 'samples',
  shortCode: 'samples',
  exportRoute: 'samples/export',
  sections: [
    {
      options: [
        {
          filterMethod: 'keywords',
          filterPropertyKeys: ['search'],
          filterMethodConfig: {
            useForSearch: true,
            title: 'Search',
            label: 'By Sample ID',
          },
        },
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'tows',
          },
          filterPropertyKeys: ['towId'],
          definitionAlias: 'tows',
          filterValueType: 'OBJECT_ID',
        },
        {
          filterMethod: 'reference-autocomplete',
          filterMethodConfig: {
            entityDefinitionAlias: 'users',
          },
          filterPropertyKeys: ['userId'],
          definitionAlias: 'users',
          filterValueType: 'OBJECT_ID',
        },
        {
          filterMethod: 'years-drop-down',
          filterMethodConfig: {
            title: 'Midpoint Date',
            min: 1900,
            max: 2026,
            reversed: true,
            asDate: true,
          },
          filterPropertyKeys: ['sampleMidpointDate'],
          filterValueType: 'DATE',
        },
        {
          optionId: 'status',
          filterMethod: 'radio',
          filterMethodConfig: {
            title: 'Status',
            usingExtraFilterRules: true,
            optionsColumns: 2,
            options: [
              {
                name: 'Any',
                extraFilterRules: [],
              },
              {
                name: 'Unallocated',
                extraFilterRules: [
                  {
                    key: 'userId',
                    con: [{ type: FilterConditionType.NotExists }],
                  },
                  {
                    key: 'finalisedAt',
                    con: [{ type: FilterConditionType.NotExists }],
                  },
                ],
              },
              {
                name: 'Analyzing',
                extraFilterRules: [
                  {
                    key: 'userId',
                    con: [{ type: FilterConditionType.Exists }],
                  },
                  {
                    key: 'finalisedAt',
                    con: [{ type: FilterConditionType.NotExists }],
                  },
                ],
              },
              {
                name: 'Finalised',
                extraFilterRules: [
                  {
                    key: 'finalisedAt',
                    con: [{ type: FilterConditionType.Exists }],
                  },
                ],
              },
            ],
            defaultValue: {},
          },
          filterPropertyKeys: ['finalisedAt'],
        },
      ],
    },
  ],
};
