<div class="login-container">
  <section>
    <img src="/assets/logo/cpr-full.svg" width="100%" />
  </section>

  <section class="sign-in">
    <h1>Sign In</h1>
    <mat-divider></mat-divider>

    <button
      mat-raised-button
      class="ms-azure-login-btn"
      [disabled]="loginStatus == 'IN_PROGRESS' || loginStatus == 'SUCCESS'"
      (click)="startAzureLogin()"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <!-- <mat-icon>azure</mat-icon> -->
        <img src="/assets/icons/microsoft.ico" />
        <span>Sign in with Microsoft Azure AD</span>
      </div>
    </button>

    <div class="loading-overlay" *ngIf="loginStatus == 'IN_PROGRESS' || loginStatus == 'SUCCESS'">
      <mat-spinner></mat-spinner>
      <br>
      <span>{{
        loginStatus == 'SUCCESS'
          ? 'Sign in successful, please wait...'
          : 'Please continue to sign in using your Microsoft account. If the Microsoft login form does not open in another tab or window, please ensure pop ups are not blocked on this site.'
      }}</span>
    </div>

    <div *ngIf="loginStatus == 'FAILED'" class="error">
      <span>There was a problem signing you in.</span>
    </div>

    <!-- <p>Login status: {{ loginStatus }}</p> -->
  </section>
</div>
