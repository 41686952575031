import { TOWS_FILTERS_SECTIONS } from './tows-filters-sections.const';
import { SAMPLES_FILTERS_SECTIONS } from './samples-filters-sections.const';
import { AUDIT_FILTERS_SECTIONS } from './audit-filters-sections.const';
import { SHIPS_FILTERS_SECTIONS } from './ships-filters-sections.const';
import { USERS_FILTERS_SECTIONS } from './users-filters-sections.const';

export const FILTERS_SECTIONS = {
  tows: TOWS_FILTERS_SECTIONS,
  samples: SAMPLES_FILTERS_SECTIONS,
  audit: AUDIT_FILTERS_SECTIONS,
  ships: SHIPS_FILTERS_SECTIONS,
  users: USERS_FILTERS_SECTIONS,
};
