import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditComponentModule } from '../../../shared/components/edit/edit.component.module';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { KnowledgeBaseSearchComponent } from './components/knowledge-base-search/knowledge-base-search.component';
import { TaxonOverviewComponent } from './components/taxon-overview/taxon-overview.component';
import { TaxonLineageComponent } from './components/taxon-lineage/taxon-lineage.component';
import { KnowledgeBaseRoutingModule } from './knowledge-base-routing.module';
import { KnowledgeBaseLayoutComponent } from './layout/knowledge-base-layout.component';
import { KnowledgeBaseOverviewScreenComponent } from './screen/overview/knowledge-base-overview-screen.component';
import { KnowledgeBaseTaxonScreenComponent } from './screen/taxon/knowledge-base-taxon-screen.component';
import { PermissionsModule } from '../permissions/permissions.module';
import { TaxonInUseBadgeComponent } from './components/in-use-badge.component';
import { TaxonHistoryComponent } from './components/taxon-history/taxon-history.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    KnowledgeBaseRoutingModule,
    EditComponentModule,
    HeaderComponent,
    PermissionsModule,
    TaxonInUseBadgeComponent,
    TaxonHistoryComponent,
  ],
  declarations: [
    KnowledgeBaseLayoutComponent,
    KnowledgeBaseOverviewScreenComponent,
    KnowledgeBaseTaxonScreenComponent,
    KnowledgeBaseSearchComponent,
    TaxonOverviewComponent,
    TaxonLineageComponent,
  ],
})
export class KnowledgeBaseModule {}
