import { Component, OnDestroy, OnInit } from '@angular/core';
import { OAuthProvider } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type LoginStatus = 'PENDING' | 'IN_PROGRESS' | 'FAILED' | 'SUCCESS';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss'],
})
export class LoginScreenComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<boolean>();
  private readonly authProvider: OAuthProvider;

  loginStatus: LoginStatus = 'PENDING';
  loginTimeout = null;

  constructor(
    private readonly fireAuth: AngularFireAuth,
    private readonly router: Router,
  ) {
    this.authProvider = new OAuthProvider('microsoft.com');
    this.authProvider.setCustomParameters({
      tenant: environment.activeDirectoryTenantID,
    });
  }

  ngOnInit() {
    this.fireAuth.user.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.loginStatus = user ? 'SUCCESS' : 'PENDING';
      // TODO: Add a guard to validate the user's AD tenant
      this.router.navigate(['/overview']);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }

  startAzureLogin() {
    this.loginStatus = 'IN_PROGRESS';
    // this.fireAuth.signInWithRedirect(this.authProvider);
    this.fireAuth
      .signInWithPopup(this.authProvider)
      .then(() => (this.loginStatus = 'SUCCESS'))
      .catch((error) => {
        this.loginStatus = 'FAILED';
        console.error(error);
      });

      if(this.loginTimeout) {
        clearTimeout(this.loginTimeout);
      }

    this.loginTimeout = setTimeout(() => {
      if(this.loginStatus == 'IN_PROGRESS'){
      this.loginStatus = 'PENDING';
      }
    }, 120000);
  }
}
