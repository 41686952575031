import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SEOService } from '@suvo-bi-core';
import { SessionService } from '@suvo-bi-lib';
import { OrganisationRoleService, TenantRoleService, UsersService } from '@suvo-bi-users';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { GoogleAnalyticsService } from './features/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'MBA CPR Survey Portal';
  loading = false; //Set to true to enable loading screen

  unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    private tenantRoleService: TenantRoleService,
    private organisationRoleService: OrganisationRoleService,
    // private angularFireAuth: AngularFireAuth,
    private router: Router,
    private userService: UsersService,
    private sessionService: SessionService,
    private ccService: NgcCookieConsentService,
    private activatedRoute: ActivatedRoute,
    private seoService: SEOService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
    // this.angularFireAuth.authState.subscribe(async (user) => {
    //   if (user) {
    //     //this.loading = true;
    //     await this.tenantRoleService.loadRole();
    //   }
    // });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.loading = false;

        this.pageView(val.urlAfterRedirects);

        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        let matDrawer = document.getElementsByTagName('mat-drawer-content');
        let matSidenav = document.getElementsByTagName('mat-sidenav-content');

        if (matDrawer && matDrawer.length) {
          matDrawer[0].scrollTo(0, 0);
        }

        if (matSidenav && matSidenav.length) {
          matSidenav[0].scrollTo(0, 0);
        }
      }

      let userId = this.userService.userSubject?.value?._id;

      if (userId) {
        this.sessionService.handleRouteChange(userId, val);
      }
    });
  }

  ngOnInit() {
    const dismissed = localStorage.getItem('cookieconsent');

    if (!dismissed) {
      this.ccService.open();
    }
    this.ccService.statusChange$.pipe(takeUntil(this.unsubscribe$)).subscribe((response: any) => {
      if (response.status === 'dismiss') {
        localStorage.setItem('cookieconsent', 'true');
      }
    });

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((routeData) => {
        if (routeData.seo) {
          this.seoService.updateTitle(routeData.seo['title']);
          //Updating Description tag dynamically with title
          this.seoService.updateDescription(routeData.seo['description']);
        }
      });
  }

  onActivate(event) {
    document.body.scrollTop = 0;
  }

  pageView(urlAfterRedirects) {
    this.googleAnalyticsService.gtag({
      page_path: urlAfterRedirects,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
  }
}
